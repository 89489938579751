* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  width: 100% !important;
  height: 100% !important;
  /* min-width: 1366px; */
  min-width: 320px;
}

.form__container {
  --primary: #522d6d;
  --primary-dark: #391f4c;
  --primary-light: rgba(250, 26, 26, 0.1);
  --secondary: #d69fff;
  --secondary-back-light: #e3f2fd;
  --background: #fff;
  --background-dim: #eeeeee;
  --background-dim-light: #f0ebf7;
  --foreground: #000000de;
  --border-inside: rgba(0, 0, 0, 0.16);
  --button-text: #fff;
  --app-paper-background: #e3f2fd;
}
.form__container.dark {
  --primary: #7c4dff;
  --primary-dark: #5635b2;
  --primary-light: rgba(26, 183, 250, 0.1);
  --secondary: #d69fff;
  --secondary-back-light: #e3f2fd;
  --background: #111936;
  --background-dim: #212a45;
  --background-dim-light: #212a45;
  --foreground: #ededed;
  --border-inside: rgba(255, 255, 255, 0.4);
  --button-text: #fff;
  --app-paper-background: #212a45;
}

.form___action-button button {
  background-color: var(--primary);
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.form___action-button span {
  color: var(--button-text);
}
.form___action-button button:hover {
  background-color: var(--primary-dark);
}

.sd-body {
  background-color: var(--app-paper-background);
}
